const mainHeader = document.getElementById('desktop-header');
const desktopCloseButton = document.querySelectorAll('.icon-close');
const underlay = document.getElementById('header-underlay');
const desktopMenuButton = document.querySelector('.icon-menu');
const drilldownContainer = document.querySelector('.drilldown-container');
const drilldownUnderlay = document.getElementById('drilldown-underlay');
const searchModal = document.getElementById('search-modal');
const searchButton = document.querySelector('[data-open-search-modal]');
const closeButton = document.querySelector('[data-close-search-modal]');
const navItems = document.querySelectorAll('.main-navigation nav [data-button-expander]');
const itemLists = document.querySelectorAll('.item-list');
const expanderButtons = document.querySelectorAll('[data-button-expander]');

let isMenuExpanded = false;
let isDrilldownExpanded = false;
let currentOpenId = null;
let isSearchExpanded = false;

function showSubmenus(id) {
    document.querySelectorAll('.submenu-list, .item-list').forEach(function (submenu) {
        submenu.classList.add('hide');
    });

    document.querySelectorAll(`[data-menu-id="${id}"]`).forEach(function (submenu) {
        submenu.classList.remove('hide');
    });
}

document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
        closeMenu();
    }
});

function closeMenu() {
    if(currentOpenId){
        expanderButtons[currentOpenId - 1].focus();
    }
    else if(isSearchExpanded){
        searchButton.focus();
    }
    else if(isDrilldownExpanded){
        drilldownUnderlay.focus();
    }
    else if(isMenuExpanded){
        desktopMenuButton.focus();
    }
    else{
        expanderButtons[0].focus();
    }

    currentOpenId = null;
    isMenuExpanded = false;
    isDrilldownExpanded = false;
    drilldownUnderlay.classList.remove('active');
    drilldownContainer.classList.remove('active');
    drilldownContainer.setAttribute('aria-hidden', 'true');
    drilldownContainer.setAttribute('tabindex', '-1');
    drilldownContainer.setAttribute('inert','');
    drilldownContainer.setAttribute('aria-expanded','false');
    underlay.classList.remove('active');
    mainHeader.classList.remove('expanded');
    mainHeader.setAttribute('aria-expanded','false');
    

    document.querySelectorAll('[data-button-expander]').forEach(function (button) {
        button.classList.remove('active');
    });
}

document.querySelectorAll('[data-button-expander]').forEach(function (button) {
    button.addEventListener('click', function (e) {
        e.preventDefault();

        underlay.classList.add('active');

        document.querySelectorAll('[data-button-expander]').forEach(function (button) {
            button.classList.remove('active');
        }); 

        if (isMenuExpanded && currentOpenId === e.target.closest('button').getAttribute('data-id')) {
            closeMenu();
        } else {
            currentOpenId = e.target.closest('button').getAttribute('data-id');
            showSubmenus(currentOpenId);
            e.target.closest('button').classList.toggle('active');
            mainHeader.classList.add('expanded');
            mainHeader.setAttribute('aria-expanded','true');
            isMenuExpanded = true;;
        }
    });
});

underlay.addEventListener('click', function () {
    closeMenu();
});

drilldownUnderlay.addEventListener('click', function () {
    closeMenu();
}); 

window.addEventListener('scroll', function () {
    let currentScrollPosition = window.scrollY;

    if (currentScrollPosition > 0) {
        mainHeader.classList.add('active');
    } else {
        mainHeader.classList.remove('active');
    }
});

desktopCloseButton.forEach(function (closeButton) {
    closeButton.addEventListener('click', function (e) {
        e.preventDefault();
        closeMenu();
    });
});

desktopMenuButton.addEventListener('click', function () {
    isDrilldownExpanded = true;
    drilldownUnderlay.classList.add('active');
    drilldownContainer.classList.add('active');
    drilldownContainer.setAttribute('aria-hidden', 'false');
    drilldownContainer.setAttribute('tabindex', '0');
    drilldownContainer.removeAttribute('inert');
    drilldownContainer.setAttribute('aria-expanded','true') ;
});

function closeSearchModal(){
    isSearchExpanded = false;
    searchModal.setAttribute('tabindex','-1');
    searchModal.setAttribute('inert','');
    searchModal.setAttribute('aria-expanded','false');
    searchModal.classList.remove('active');
    document.body.classList.remove('no-scroll');
}

searchButton.addEventListener('click', function() {
    if(isSearchExpanded){
        closeSearchModal()
    }
    else {
        closeMenu();
        isSearchExpanded = true;
        searchModal.setAttribute('tabindex','1');
        searchModal.removeAttribute('inert');
        searchModal.setAttribute('aria-expanded','true');
        searchModal.classList.add('active');
        document.body.classList.add('no-scroll');
        searchModal.querySelector('[data-search-query]').focus();
    }
});

document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape' && searchModal.classList.contains('active')) {
        closeSearchModal();
    }
});

closeButton.addEventListener('click', function() {
    closeSearchModal();
});

document.getElementById('jump-to-content').addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('maincontent').focus();
});

navItems.forEach((item) => {
    item.addEventListener('click', (event) => {
        event.preventDefault();

        const submenuId = item.getAttribute('data-id');
        const submenu = document.querySelector(`.submenu-list[data-menu-id="${submenuId}"]`);

        if (submenu) {
            const firstSubmenuEntry = submenu.querySelector('.submenu-entry');
            if (firstSubmenuEntry) {
                firstSubmenuEntry.focus();
                
                // Listen for Shift + Tab on the first submenu entry
                firstSubmenuEntry.addEventListener('keydown', (event) => {
                    if (event.shiftKey && event.key === 'Tab') {
                        event.preventDefault();
                        item.focus();
                    }
                });
            }
        }
    });
});

const focusNextExpander = (element, expanderButtons) => {
    if (element) {
        element.addEventListener('keydown', (event) => {
            if (event.key === 'Tab' && !event.shiftKey) {
                event.preventDefault();

                const currentOpenIdNumber = parseInt(currentOpenId, 10);

                if (expanderButtons[currentOpenIdNumber]) {
                    expanderButtons[currentOpenIdNumber].focus();
                } else {
                    expanderButtons[0].focus();
                }
            }
        });
    }
};

itemLists.forEach((itemList) => {
    const lastEntry = itemList.querySelector('.entry:last-of-type');
    const isLastEntryVisible = lastEntry && window.getComputedStyle(lastEntry).display !== 'none';

    if (isLastEntryVisible) {
        focusNextExpander(lastEntry, expanderButtons);
    } else {
        const submenuId = itemList.getAttribute('data-menu-id');
        const submenu = document.querySelector(`.submenu-list[data-menu-id="${submenuId}"]`);

        if (submenu) {
            const lastSubmenuEntry = submenu.querySelector('.submenu-entry:last-of-type');
            focusNextExpander(lastSubmenuEntry, expanderButtons);
        }
    }
});