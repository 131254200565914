const promoComponents = document.querySelectorAll(".blocklist-component-promo-module");

promoComponents.forEach(promoComponent => {
  const promoLinks = promoComponent.querySelectorAll(".promo-link");

  if (promoLinks.length > 0) {
    promoLinks.forEach((link) => {
      link.addEventListener("click", function (event) {
        removeClasses(promoComponent, promoLinks);

        const dataId = link.getAttribute("data-id");
        const colorLabel = link.getAttribute("data-color");

        link.classList.add("active");

        promoComponent.querySelector(`.promo-image[data-id="${dataId}"]`).classList.add("move");
        promoComponent.querySelector(`.promo-content[data-id="${dataId}"]`).classList.add("active");

        let button = promoComponent.querySelector(`.promo-content[data-id="${dataId}"] .button`);
        if (button) {
          button.classList.remove("hide");
        }

        promoComponent.classList.add(colorLabel);

        link.disabled = true;
      });
    });

    initPromo(promoComponent, promoLinks);
  }
});

function initPromo(promoComponent, promoLinks) {
  const firstPromoLink = promoLinks[0];

  const dataId = firstPromoLink.getAttribute("data-id");
  const colorLabel = firstPromoLink.getAttribute("data-color");

  firstPromoLink.classList.add("active");

  promoComponent.querySelector(`.promo-image[data-id="${dataId}"]`).classList.add("move");
  promoComponent.querySelector(`.promo-content[data-id="${dataId}"]`).classList.add("active");
  
  let button = promoComponent.querySelector(`.promo-content[data-id="${dataId}"] .button`);

  if (button) {
    button.classList.remove("hide");
  }

  promoComponent.classList.add(colorLabel);

  firstPromoLink.disabled = true;
}

function removeClasses(promoComponent, promoLinks) {
  promoComponent.querySelectorAll(".promo-image").forEach((el) => {
    el.classList.remove("move");
  });

  promoComponent.querySelectorAll(".promo-content").forEach((el) => {
    el.classList.remove("active");
    const button = el.querySelector(".button");
    if (button) {
      button.classList.add("hide");
    }
  });

  promoComponent.className = "blocklist-component-promo-module bg-div global-component has-bg";

  promoLinks.forEach((link) => {
    link.classList.remove("active");
    link.disabled = false;
  });
}