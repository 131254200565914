document.addEventListener('DOMContentLoaded', function() {
    let history = ["root-level"];
    const drillDownEntries = Array.from(document.querySelectorAll('.drilldown-entry'));
    const backButtons = Array.from(document.querySelectorAll('.drilldown-back-button'));
    const pages = Array.from(document.querySelectorAll('.drilldown-page'));
    const drilldownCloseButton = document.querySelector('.drilldown-close-button');
    const mobileNavigation = document.querySelector('.mobile-navigation');
    const drilldownContainer = document.querySelector('.drilldown-container');
    const drilldownUnderlay = document.getElementById('drilldown-underlay');
    const mobileDrillDownLinks = Array.from(document.querySelectorAll('.mobile-drilldown-entry'));

    drillDownEntries.forEach(entry => {
        entry.addEventListener('click', function() {
            const linkId = this.getAttribute('data-link-id');
            if (linkId) {
                animateOut(history[history.length - 1], 'forward').then(() => {
                    history.push(linkId);
                    animateIn(history[history.length - 1], 'forward');
                });
            }
        });
    });

    mobileDrillDownLinks.forEach(link => {
        link.addEventListener('click', function() {
            drilldownContainer.classList.add('active');
            drilldownContainer.removeAttribute('inert');
            drilldownUnderlay.classList.add('active');
            
            const linkId = this.getAttribute('data-link-id');
            if (linkId) {
                history.push(linkId);
                animateIn(linkId, 'forward');
            }
        });
    });

    backButtons.forEach(button => {
        button.addEventListener('click', function() {
            if (history.length > 2) {
                animateOut(history.pop(), 'backward').then(() => {
                    animateIn(history[history.length - 1], 'backward');
                });
            } else if (history.length === 2) {
                if (mobileNavigation.classList.contains('active')) {
                    history = ["root-level"];
                    closeDrillDownMenu();
                } else {
                    animateOut(history.pop(), 'backward').then(() => {
                        animateIn(history[history.length - 1], 'backward');
                    });
                }
            }
        });
    });

    function getPage(pageId) {
        return pages.find(page => page.getAttribute('data-page-id') === pageId);
    }

    function animateIn(pageId, direction) {
        pages.forEach(page => page.classList.remove('active'));
        
        const page = getPage(pageId);
        page.classList.add('active');
        page.animate([
            { transform: direction === 'forward' ? 'translateX(100%)' : 'translateX(-100%)', opacity: 0 },
            { transform: 'translateX(0)', opacity: 1 }
        ], {
            duration: 400,
            easing: 'ease'
        });
    }

    function animateOut(pageId, direction) {
        return new Promise((resolve, reject) => {
            const page = getPage(pageId);
            const animation = page.animate([
                { transform: 'translateX(0)', opacity: 1 },
                { transform: direction === 'forward' ? 'translateX(-100%)' : 'translateX(100%)', opacity: 0 }
            ], {
                duration: 150,
                easing: 'ease'
            });
            animation.onfinish = () => { 
                page.classList.remove('active'); 
                resolve();
            };
        });
    }

    drilldownCloseButton.addEventListener('click', function() {
        closeDrillDownMenu();
    });

    function closeDrillDownMenu() {
        drilldownContainer.classList.remove('active');
        drilldownContainer.setAttribute('inert','');
        drilldownUnderlay.classList.remove('active');
    }
});