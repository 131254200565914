import Swiper from 'swiper';
import { Navigation, Virtual, A11y } from 'swiper/modules';
import 'swiper/css';

const processOptions = {
	modules: [Navigation, Virtual, A11y],

	direction: 'horizontal',
	loop: false,
	slidesPerView: 'auto',
	spaceBetween: 0,
	grabCursor: true,
	autoHeight: true,
	observer: true,

	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	a11y: {
        prevSlideMessage: 'Forrige slide',
        nextSlideMessage: 'Næste slide',
    },
};

document.querySelectorAll('.process-component .swiper').forEach(function (el) {
	var swiper;

	const breakpoint = window.matchMedia('(min-width:1201px)');
	const breakpointChecker = function () {
		
		function closeAllItems() {
			el.querySelectorAll('[data-process-item]').forEach(item => {
				toggleItem(item, true);
			});
		}
		
		if (breakpoint.matches === true) {
			if (swiper !== undefined) swiper.destroy(true, true);
			wrapDuoItems();
			closeAllItems();

		} else if (breakpoint.matches === false) {
			unwrapDuoItems();
			closeAllItems();
			
			swiper = new Swiper(el, processOptions);
		}
	};

	const wrapDuoItems = function () {
		document.querySelectorAll('.process-component [data-duo-item]').forEach(function (el) {
			let nextSibling = el.nextElementSibling;

			if (nextSibling) {
				let wrapper = document.createElement('div');
				wrapper.className = 'duo-wrapper';

				el.parentNode.insertBefore(wrapper, el);
				wrapper.appendChild(el);
				wrapper.appendChild(nextSibling);
			}
		})
	}

	const unwrapDuoItems = function () {
		document.querySelectorAll('.duo-wrapper').forEach(function (el) {
			let parent = el.parentNode;

			while (el.firstChild) {
				parent.insertBefore(el.firstChild, el);
			}
			parent.removeChild(el);
		});
	}

	breakpoint.addEventListener('change', breakpointChecker);
	breakpointChecker();
	
	const items = el.querySelectorAll('[data-process-item]');
	items.forEach(item => {
		item.addEventListener('click', (e) => {
			const isOpen = item.classList.contains('open');
			
			items.forEach(otherItem => {
				if (otherItem !== item) {
					if (otherItem.classList.contains('open')) {
						toggleItem(otherItem, true);
					}
				}
			})
	
			if (item.matches('[data-duo-item]') && breakpoint.matches) {
				const sibling = item.nextElementSibling?.matches('[data-duo-item]') ? item.nextElementSibling : 
								item.previousElementSibling?.matches('[data-duo-item]') ? item.previousElementSibling : 
								null;
				[sibling, item].forEach(el => toggleItem(el, isOpen));
			} else {
				toggleItem(item, isOpen);
			}
		})
	})

	function toggleItem(item, isOpen) {
		if (!item) return;
		
		const toggleIcons = item.querySelector('.toggler-icons')
		const itemContent = item.querySelector('[data-process-item-content]')
		
		if (isOpen) {
			item.classList.remove('open')
			item.querySelector('.card').setAttribute('aria-expanded', 'false')
			toggleIcons?.classList.replace('opened', 'closed')
			itemContent?.removeAttribute('aria-hidden')
			itemContent.style.height = '0px'
        	itemContent.style.opacity = '0'
			
			if (breakpoint.matches === true) {
				itemContent.style.width = '0px'
			}
			else {
				itemContent.style.width = itemContent.scrollWidth + 'px'
			}

		} else {
			item.classList.add('open')
			item.querySelector('.card').setAttribute('aria-expanded', 'true')
			toggleIcons?.classList.replace('closed', 'opened')
			itemContent?.setAttribute('aria-hidden', 'false')
			
			itemContent.style.height = itemContent.scrollHeight + 'px'
			itemContent.style.opacity = '1'
			
			if (breakpoint.matches === true) {
				itemContent.style.width = itemContent.scrollWidth + 'px'
			}
		}
	}

});