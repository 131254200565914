const mobileMenu = document.querySelector('.mobile-navigation');
const mobileCloseButton = document.querySelector('.mobile-close-button');
const mobileMenuButton = document.querySelector('.icon-mobile-menu');
const mobileBackButton = document.querySelectorAll('button.back-button');
const underlay = document.getElementById('header-underlay');

let isMobileMenuExpanded = false;
let currentOpenMobilePageId = null;

function switchToMobilePage(pageId) {
    document.querySelector('.main-mobile-navigation').classList.remove('active');
    const pageTarget = document.querySelector('.mobile-page[data-page-id="' + pageId + '"]');
    pageTarget.classList.add('active');
}

function goBackToMenu() {
    document.querySelector('.main-mobile-navigation').classList.add('active');
    document.querySelector('.mobile-page.active').classList.remove('active');
}

mobileCloseButton.addEventListener('click', function () {
    isMobileMenuExpanded = false;
    document.body.classList.remove('no-scroll');
    underlay.classList.remove('active');
    mobileMenu.classList.remove('active');
    mobileMenu.setAttribute('inert','');
    mobileMenu.setAttribute('aria-expanded','false');
});

mobileMenuButton.addEventListener('click', function () {
    isMobileMenuExpanded = true;
    document.body.classList.add('no-scroll');
    underlay.classList.add('active');
    mobileMenu.classList.add('active');
    mobileMenu.removeAttribute('inert');
    mobileMenu.setAttribute('aria-expanded','true');
    mobileMenu.querySelector('.mobile-close-button').focus();
});

mobileBackButton.forEach(function (button) {
    button.addEventListener('click', function () {
        goBackToMenu();
    });
});

document.querySelectorAll('[data-button-mobile-selector]').forEach(function (button) {
    button.addEventListener('click', function (e) {
        e.preventDefault();

        currentOpenMobilePageId = e.target.closest('button').getAttribute('data-page-id');
        switchToMobilePage(currentOpenMobilePageId);
    });
});

