var searchForms = document.querySelectorAll("[data-search-form]");
var searchInputs = document.querySelectorAll("[data-search-query]");

searchForms.forEach(function(form) {
    const showAll = form.querySelector("[data-show-all]");
    const searchQuery = form.querySelector("[data-search-query]");

    searchQuery.addEventListener("keydown", function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            window.location.href = window.location.origin + '/soegning?q=' + searchQuery.value;
        }
    });

    if (showAll) {
        showAll.addEventListener("click", function (e) {
            e.preventDefault();
            window.location.href = window.location.origin + '/soegning?q=' + searchQuery.value + '&show_all=true';
        });
    }
});

searchInputs.forEach(function(input) {
    input.addEventListener("keyup", formDebounce(function (e) {
        if (input.value === '') {
            input.createSuggestions({results: []});
            return;
        }

        let q = input.value;
        const url = '/api/simple-search?query=' + q;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            input.createSuggestions(data);
        });

    }, 150));
});

HTMLInputElement.prototype.createSuggestions = function(data) {
    var oldSuggestions = document.querySelector('.suggestions-container');

    if (data.results.length === 0) {
        if (oldSuggestions) {
            oldSuggestions.remove();
        }
        return;
    }

    if (oldSuggestions) {
        oldSuggestions.remove();
    }

    var suggestionsContainer = document.createElement('div');
    suggestionsContainer.className = 'suggestions-container';

    data.results.forEach(function(result) {
        var entry = document.createElement('a');
        entry.setAttribute('href', result.url);
        entry.className = 'suggestion-entry';
        entry.innerHTML = `<span class="suggestion-text">${result.suggestedInput}</span><span class="suggestion-category">${result.label}</span>`;
        suggestionsContainer.appendChild(entry);
    });

    this.parentNode.insertBefore(suggestionsContainer, this.nextSibling);
};

function formDebounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}