import Swiper from 'swiper';
import { Navigation, Virtual, A11y } from 'swiper/modules';
import 'swiper/css';

const defaultOptions = {
	modules: [Navigation, Virtual, A11y],

	direction: 'horizontal',
	loop: false,
	slidesPerView: 1,
	spaceBetween: 16,
	grabCursor: true,

	breakpoints: {
		601: {
			slidesPerView: 2,
		},
		1201: {
			slidesPerView: 4,
		},
	},

	navigation: {
		nextEl: '.swiper-alt-navigation .swiper-button-next',
		prevEl: '.swiper-alt-navigation .swiper-button-prev',
	},
	a11y: {
        prevSlideMessage: 'Forrige slide',
        nextSlideMessage: 'Næste slide',
    },
};

document.querySelectorAll('.blocklist-component-carousel:not(.has-filters, .blocklist-component-carousel-gallery) .swiper').forEach(function (el) {
	var swiper = new Swiper(el, defaultOptions);
});

document.querySelectorAll('.blocklist-component-carousel-gallery .swiper').forEach(function (el) {
	var swiper = new Swiper(el, {
		modules: [Navigation, Virtual, A11y],

		direction: 'horizontal',
		loop: false,
		slidesPerView: 1,
		spaceBetween: 16,
		grabCursor: true,

		breakpoints: {
			601: {
				slidesPerView: 2,
			},
			1201: {
				slidesPerView: 3,
			}
		},

		navigation: {
			nextEl: '.swiper-alt-navigation .swiper-button-next',
			prevEl: '.swiper-alt-navigation .swiper-button-prev',
		},
		a11y: {
			prevSlideMessage: 'Forrige slide',
			nextSlideMessage: 'Næste slide',
		},
	});
});

const categoryOptions = {
	modules: [Navigation, Virtual, A11y],
	direction: 'horizontal',
	loop: false,
	slidesPerView: 'auto',
	spaceBetween: 16,
	grabCursor: true,
	nested: true,

	navigation: {
		nextEl: '.swiper-navigation .swiper-button-next',
		prevEl: '.swiper-navigation .swiper-button-prev',
	},
	a11y: {
		prevSlideMessage: 'Forrige slide',
		nextSlideMessage: 'Næste slide',
	},
};

document.querySelectorAll('.blocklist-component-carousel.has-filters').forEach(function (el) {
	let swiperOuter = new Swiper(el.querySelector('.swiper.outer'), defaultOptions),
		swiperInner = new Swiper(el.querySelector('.swiper.inner'), categoryOptions),
		buttons 	= el.querySelectorAll('.categories button'),
		cards 		= el.querySelectorAll('.swiper-slide.carousel-card-education');
	
	buttons.forEach(function (button) {
		button.addEventListener('click', function (e) {

			buttons.forEach(button => button.classList.remove('active'));
			this.classList.add('active');

			let categoryId = this.getAttribute('data-category');

			cards.forEach(function (card) {
				if (card.getAttribute('data-category') === categoryId) {
					card.style.display = 'block';
					card.classList.add('updated');
				} else {
					card.style.display = 'none';
				}
			});

			swiperOuter.update();
		});
	});

	function handleOverflow(){
		let filterWrapper = el.querySelector('.swiper.inner .swiper-wrapper');
		if (filterWrapper.scrollWidth > filterWrapper.clientWidth) {
			filterWrapper.parentNode.classList.add('overflowing');
		}else {
			filterWrapper.parentNode.classList.remove('overflowing');
		}
	}

	handleOverflow()
	swiperInner.on('resize', function() {
		handleOverflow()
	});

	let paramString = window.location.search;
	let match = paramString.match(/category=([^&]*)/);
	let categoryId = match ? match[1] : null;

	if (categoryId) {
		let button = el.querySelector(`button[data-category="${categoryId}"]`);
		if (button) {
			button.click();
			let index = Array.from(buttons).findIndex(b => b.getAttribute('data-category') === categoryId);
			swiperInner.slideTo(index);
		}
		else {
			buttons[0].click();
		}
	}
});