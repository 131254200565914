const videoElement = document.querySelector('.hero-video');

if (videoElement) {
    let isVideoPaused = false; 

    document.querySelector('.video-controls').addEventListener('click', function (e) {
        e.preventDefault();
        e.target.closest('.video-controls').classList.toggle('playing');

        if (isVideoPaused) {
            videoElement.play();
            isVideoPaused = false;
        } else {
            videoElement.pause();
            isVideoPaused = true;
        }
    });
}