const formsToClean = document.querySelectorAll('[data-remove-injected-styles]');

function removeInjectedStyles() {
    formsToClean.forEach(form => {
        form.classList.add('cleaned');
        
        let elements = form.getElementsByTagName('*');

        for(let i = elements.length - 1; i >= 0; i--) {
            var displayStyle = '';
            if(elements[i].style.display !== 'none'){
                displayStyle = elements[i].style.display;
                elements[i].removeAttribute('style');
            }

            elements[i].className = '';

            if(displayStyle !== ''){
                elements[i].style.display = displayStyle;
            }

            if(elements[i].tagName.toLowerCase() === 'div' && elements[i].childNodes.length === 1 
               && elements[i].innerHTML.includes('&nbsp;')) {
                elements[i].parentNode.removeChild(elements[i]);
            }
        }
    });
}

function observerCallback(mutationsList, observer) {
    observer.disconnect();

    for(let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
            removeInjectedStyles();
        }
    }

    formsToClean.forEach(form => {
        observer.observe(form, config);
    });
}

const config = { attributes: true, childList: true, subtree: true };
const observer = new MutationObserver(observerCallback);
formsToClean.forEach(form => {
    observer.observe(form, config);
})